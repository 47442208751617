<template>
    <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;">
        
        <Breadcrumbs class="inlineblock" />

        <!-- Action on right click -->
        <RenameDeleteActions
            v-if="showMenu"
            v-model="showMenu"

            :x="x"
            :y="y"

            :renameAction="renamePlate"
            :renameName="$t('general.rename')"
            :deleteAction="confirmDelete"
            :deleteName="$t('general.delete')"
        />

        <!-- Creating a new plate -->
        <NamingDialog
            v-if="isAddingPlate"
            v-model="isAddingPlate"

            :headlineName="$t('supportingplates.add_supporting_plate')"
            :typeName="$t('general.name')"

            :cancelName="$t('general.cancel')"                
            :actionName="$t('general.add')"
            :actionFunction="confirmAddPlate"
        />

        <!-- Renaming an existing plate -->
        <NamingDialog
            v-if="isEditingPlate"
            v-model="isEditingPlate"

            :baseValue="editedPlate.name"

            :headlineName="$t('supportingplates.rename_supporting_plate') + ' ' + editedPlate.name"
            :typeName="$t('general.name')"

            :cancelName="$t('general.cancel')"                
            :actionName="$t('general.rename')"
            :actionFunction="savePlate"
        />
        
        <!-- STANDARD SUPPORTING PLATES LIST -->
        <v-container fluid>
            <v-row>
                <v-col sm="6" cols="12">
                    <v-card class="align-center pa-5">
                        <v-row v-if="selectedSupportingPlateId != -1">
                            <v-spacer/>
                                <v-btn
                                rounded
                                small
                                color="primary"
                                dark
                                style="text-transform:none"
                                @click="renamePlate"
                            >
                            {{ $t('general.rename') }}
                            </v-btn>
                            <v-btn
                                style="text-transform:none"
                                rounded
                                small
                                color="red"
                                dark
                                @click="confirmDelete"
                            >
                            {{ $t('general.delete') }}
                            </v-btn>
                        </v-row>
                        <v-row>
                            <v-subheader>{{ $t("general.plates") }}</v-subheader>
                            <v-list dense style="width:100%;max-height:60vh;overflow:auto;">
                                <v-list-item
                                v-for="(item, i) in allStandardSupportingPlates.filter((pt) => pt.id != -1)"
                                :class="{
                                    'active-supportingplates': item.id == selectedSupportingPlateId,
                                }"
                                :key="i"
                                @click="setSelected(item.id)"
                                @contextmenu="show($event, item)"
                                >
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-row>
                        <v-row>
                            <v-btn
                            @click="add"
                            class="ma-2 add-pt-btn"
                            rounded
                            color="primary"
                            dark
                            >{{ $t("supportingplates.add_supporting_plate") }}</v-btn>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col sm="6" cols="12">
                    <v-card class="align-center pa-5" v-if="selectedSupportingPlateId != -1">
                        <v-subheader style="font-size: 0.75rem">{{
                            $t("supportingplates.standard_supporting_plate")
                        }}</v-subheader>

                        <v-row>
                            <v-col cols="6">
                                <v-select
                                :items="[ { name: $t('reportInfo.fix'), id: 1 }, { name: $t('reportInfo.adjustable'), id: 0 } ]"
                                label="Mobility"
                                v-model="selectedPlate.mobility"
                                item-text="name"
                                item-value="id"
                                outlined
                                @change="saveSupportingPlateInfo"
                            ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    :items="[ { name: $t('reportInfo.square'), id: PLATE_FORMS.SQUARE }, { name: $t('reportInfo.u'), id: PLATE_FORMS.U }, { name: $t('reportInfo.l'), id: PLATE_FORMS.L }, { name: $t('reportInfo.box'), id: PLATE_FORMS.BOX }, { name: $t('reportInfo.underpinning'), id: PLATE_FORMS.UNDERPINNING } ]"
                                    label="Form"
                                    item-text="name"
                                    item-value="id"
                                    v-model="selectedPlate.form"
                                    outlined
                                    @change="saveSupportingPlateInfo"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card class="align-center" style="margin-top: 20px;" v-if="selectedSupportingPlateId != -1">
                        <v-subheader style="font-size: 0.75rem">
                            {{ $t("projecttypes.type_translations") }}
                        </v-subheader>
                        <v-data-table
                            :headers="headers"
                            :items="selectedPlateTranslations"
                            @click:row="editTranslation"
                            item-key="index"
                            disable-pagination
                            hide-default-footer
                        >
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- STANDARD SUPPORTING PLATES LIST -->

        <!-- EDIT SUPPORTING PLATE TRANSLATION DIALOG -->
        <v-dialog
            v-if="isEditingTranslation"
            v-model="isEditingTranslation"

            persistent
            max-width="600"
        >
            <v-card>
                <v-card-title>
                    {{ $t("supportingplates.edit_translation") }}
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        @keyup.enter="saveTranslation"
                        maxlength="255"

                        :label="'Text'"
                        outlined
                        v-model="editedTranslation.name"

                    ></v-text-field>
                    <v-select
                        :items="availableLanguages"
                        label="Languages"
                        v-model="editedTranslation.language"
                        disabled
                        outlined
                    ></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        @click="isEditingTranslation = false"
                        class="no-transform"
                    >
                    {{ $t("general.cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green"
                        dark
                        @click="saveTranslation"
                        class="no-transform"
                    >
                    {{ $t("general.rename") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- EDIT PROJECT TYPE TRANSLATION DIALOG -->

        <ConfirmDialog
            v-if="confirmDialog"
            v-model="confirmDialog"

            :title="$t('confirm.head')"
            :description="$t('confirm.delete_plate')"

            :cancelName="$t('reportInfo.no')"
            :actionName="$t('reportInfo.yes')"
            :actionFunction="deletePlate"
        />
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import Breadcrumbs from "@/components/Breadcrumbs";
import RenameDeleteActions from "@/components/actions/RenameDeleteActions.vue";
import NamingDialog from '@/components/dialogs/NamingDialog.vue';
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import { PLATE_FORMS } from "@/enums";
import UserService from "@/services/user.service";

export default {
    name: "Plates",
    components: {
        Breadcrumbs,
        RenameDeleteActions,
        NamingDialog,
        ConfirmDialog
    },
    data() {
        return {
            x: 0,
            y: 0,
            allStandardSupportingPlates: [],
            isEditingPlate: false,
            isEditingPlateInfo: false,
            showMenu: false,
            selectedPlate: null,
            editedPlate: null,
            editedPlateInfo: null,
            selectedSupportingPlateId: -1,
            isAddingPlate: false,
            headers: [
                {
                text: "Name",
                value: "name",
                width: "50%",
                },
                { text: "Language", value: "language" },
            ],
            isEditingTranslation: false,
            editedTranslation: null,
            confirmDialog: false,
            PLATE_FORMS: PLATE_FORMS
        };
    },
    computed: {
        ...mapState({
            isMobile: (state) => state.isMobile,
            drawer: (state) => state.drawer,
            availableLanguages: (state) => state.availableLanguages,
        }),
        selectedPlateTranslations() {
            var translations = [];
            let plate = this.allStandardSupportingPlates.find((pt) => pt.id == this.selectedSupportingPlateId);
            var foundTranslations = null;
            if (plate) {
                foundTranslations = plate.standard_supporting_plates_translations;
            }

            if (foundTranslations) {
                for (var i = 0; i < this.availableLanguages.length; i++) {
                    var translation = foundTranslations.find(
                        (t) => t.language == this.availableLanguages[i]
                    );
                    var id = -1;
                    var name = "";

                    if (translation) {
                        id = translation.id;
                        name = translation.name;
                    }

                    translations.push({
                        id: id,
                        name: name,
                        language: this.availableLanguages[i],
                    });
                }
            }

            return translations;
        },
    },
    mounted() {
        this.setBreadcrumbs([
            { text: this.$t("general.settings"), href: "/settings" },
            { text: this.$t("general.plates"), href: "/settings/plates" },
        ]);
        this.getSupportingPlates(this.$i18n.locale).then(() => {});
    },
    methods: {
        ...mapMutations(['openToast', 'SET_AVAILABLE_LANGUAGES']),
        ...mapActions({
            setBreadcrumbs: "setBreadcrumbs",
        }),
        setSelected(id) {
            this.selectedPlate = this.allStandardSupportingPlates.find(x => x.id == id);
            this.selectedSupportingPlateId = id;
        },
        show(e, item) {
            e.preventDefault();
            this.selectedPlate = item;
            this.showMenu = false;
            this.x = e.clientX;
            this.y = e.clientY;
            this.$nextTick(() => {
                this.showMenu = true;
            });
        },
        renamePlate() {
            this.isEditingPlate = true;
            this.editedPlate = JSON.parse(JSON.stringify(this.selectedPlate));
        },
        confirmDelete() {
            this.confirmDialog = true;
        },
        add() {
            this.isAddingPlate = true;
        },
        confirmAddPlate(newPlateName) {

            this.isAddingPlate = false;
            
            this.addSupportingPlate({ plateName: newPlateName }).then(() => {

                let newPlate = this.allStandardSupportingPlates.find(p => p.name == newPlateName);
                if (newPlate) {
                    this.setSelected(newPlate.id);
                }
                this.$forceUpdate();
            });
        },
        editTranslation(translation) {
            this.isEditingTranslation = true;
            this.editedTranslation = JSON.parse(JSON.stringify(translation));
        },
        async saveTranslation() {
            let changes = {
                changes: {
                    supportingPlateId: this.selectedSupportingPlateId,
                    supportingPlateTranslationId: this.editedTranslation.id,
                    translationName: this.editedTranslation.name,
                    language: this.editedTranslation.language,
                },
            };

            let response = await UserService.saveSupportingPlateTranslation(changes);
            this.isEditingTranslation = false;
            this.SET_ALL_STANDARD_SUPPORTING_PLATES(response.data);
        },
        deletePlate() {
            this.deleteSupportingPlate(this.selectedPlate, this.$i18n.locale).then(() => {
                this.setSelected(this.allStandardSupportingPlates[0].id);
                this.$forceUpdate();
            });

            this.confirmDialog = false;
        },
        savePlate(newPlateName) {
            let changes = {
                changes: {
                    supportingPlateId: this.editedPlate.id,
                    name: newPlateName,
                },
            };

            this.selectedPlate.name = newPlateName;

            this.saveSupportingPlate(changes, this.$i18n.locale).then(() => {
                this.isEditingPlate = false;
            });
        },
        editSupportingPlate(plateInfo) {
            this.isEditingPlateInfo = true;
            this.editedPlateInfo = JSON.parse(JSON.stringify(plateInfo));
            //console.log(plateInfo);
        },
        saveSupportingPlateInfo() {
            let changes = {
                changes: {
                    supportingPlateId: this.selectedSupportingPlateId,
                    mobility: this.selectedPlate.mobility,
                    form: this.selectedPlate.form,
                },
            };

            this.saveSupportingPlate(changes, this.$i18n.locale).then(() => {
                this.isEditingPlateInfo = false;
            });
        },
        async getSupportingPlates() {
            let response = await UserService.getSupportingPlates();
            this.SET_ALL_STANDARD_SUPPORTING_PLATES(response.data);
        },
        async addSupportingPlate(data) {
            let response = await UserService.addSupportingPlate(data);
            this.SET_ALL_STANDARD_SUPPORTING_PLATES(response.data);
        },
        async saveSupportingPlate(data) {
            let response = await UserService.saveSupportingPlate(data);
            this.SET_ALL_STANDARD_SUPPORTING_PLATES(response.data);
        },
        async deleteSupportingPlate(data) {
            try {
                let response = await UserService.deleteSupportingPlate(data);

                this.SET_ALL_STANDARD_SUPPORTING_PLATES(response.data);
                this.openToast({ message: this.$t("supportingplates.supporting_plate_deleted"), duration: 5000, color: 'green'});
            }
            catch (err) {
                this.openToast({ message: this.$t("supportingplates.supporting_plate_in_use"), duration: 5000, color: 'red'});
            }
        },
        SET_ALL_STANDARD_SUPPORTING_PLATES(data) {
            this.allStandardSupportingPlates = data.supportingPlates;
            if (data.languages) {
                this.SET_AVAILABLE_LANGUAGES(data.languages);
            }
        },
    }
}
</script>

<style scoped>
.active-supportingplates {
  border-radius: 8px;
  background: rgba(0, 159, 77, 0.1);
  color: #009f4d;
}
.add-pt-btn {
  left: calc(50% - 120px);
  text-transform: none;
}
.no-transform {
  text-transform: none;
}
tr {
  cursor: pointer !important;
}
</style>